/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import { Link, useNavigate } from "react-router-dom";

// Images
import logoPDF from "assets/images/pdf.png";
import { URL_API } from "config";

export default function data(data) {
  const [dados, setDados] = useState([]);
  const navigate = useNavigate();

  const avatars = (members) =>
    Object.values(members).map(({ user_id, user_image, user_nome }) => (
      <Tooltip key={user_id} title={user_nome} placeholder="bottom">
        <MDAvatar
          src={`${URL_API}/${user_image}`}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const Company = ({ id, image, name }) => (
    <MDBox
      display="flex"
      alignItems="center"
      lineHeight={1}
      style={{ cursor: "pointer" }}
      onClick={() => navigate(`/projetos/${id}`)}
    >
      <MDAvatar src={image} name={name} size="sm" style={{ borderRadius: 0 }} />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  function modelo(projeto) {
    return {
      companies: <Company id={projeto?.projeto_id} image={logoPDF} name={projeto?.projeto_nome} />,
      members: (
        <MDBox display="flex" py={1}>
          {projeto?.membros.length > 0 ? avatars(projeto?.membros) : "Ninguém"}
        </MDBox>
      ),
      budget: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {projeto?.projeto_valor}
        </MDTypography>
      ),
      completion: (
        <MDBox width="8rem" textAlign="left">
          <MDProgress
            value={projeto?.projeto_porcentagem}
            color="info"
            variant="gradient"
            label={true}
          />
        </MDBox>
      ),
    };
  }

  useEffect(() => {
    if (data?.length > 0) {
      const projetos = [];
      data?.map((projeto) => {
        projetos.push(modelo(projeto));
      });
      setDados(projetos);
    }
  }, []);

  return {
    columns: [
      { Header: "Projeto", accessor: "companies", width: "45%", align: "left" },
      { Header: "Usuários", accessor: "members", width: "10%", align: "left" },
      { Header: "Valor", accessor: "budget", align: "center" },
      { Header: "Situação", accessor: "completion", align: "center" },
    ],
    rows: dados,
  };
}
