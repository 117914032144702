/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Skeleton from "@mui/material/Skeleton";
import DataTable from "examples/Tables/DataTable";

function Data() {
  const [dados, setDados] = useState([]);

  const Company = () => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Skeleton variant="rectangular" width={40} height={40} />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        <Skeleton variant="rectangular" width={200} animation="wave" />
      </MDTypography>
    </MDBox>
  );

  function modelo() {
    return {
      companies: <Company />,
      members: (
        <MDBox display="flex" py={1}>
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton variant="circular" width={20} height={20} />
        </MDBox>
      ),
      budget: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          <Skeleton variant="rectangular" width={100} animation="wave" />
        </MDTypography>
      ),
      completion: (
        <MDBox width="8rem" textAlign="left">
          <Skeleton variant="rectangular" animation="wave" />
        </MDBox>
      ),
    };
  }

  useEffect(() => {
    const projetos = [];
    for (let i = 0; i < 5; i++) {
      projetos.push(modelo());
    }
    setDados(projetos);
  }, []);

  return {
    columns: [
      { Header: "Projeto", accessor: "companies", width: "45%", align: "left" },
      { Header: "Usuários", accessor: "members", width: "10%", align: "left" },
      { Header: "Valor", accessor: "budget", align: "center" },
      { Header: "Situação", accessor: "completion", align: "center" },
    ],
    rows: dados,
  };
}

export default function TabelaCarregando() {
  const { columns, rows } = Data();
  return (
    <DataTable
      table={{ columns, rows }}
      canSearch={false}
      pagination={false}
      isSorted={false}
      entriesPerPage={false}
      showTotalEntries={false}
      noEndBorder
    />
  );
}
