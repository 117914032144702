import axios from "axios";
import { URL_API, TOKEN_API } from "config";
class CustomAPI {
  constructor(sessao) {
    this.sessao = sessao;
  }

  async updatedFile(formData, Progress, Response) {
    try {
      const response = await axios.post(`${URL_API}/api/arquivo`, formData, {
        headers: {
          Authorization: TOKEN_API,
          Sessao: btoa(JSON.stringify(this.sessao)),
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          Progress(percentage);
        },
      });
      Response(response.data);
    } catch (error) {
      return { status: false, message: error.message };
    }
  }

  async request(url, method, data = null) {
    const headers = {
      Authorization: TOKEN_API,
      Sessao: btoa(JSON.stringify(this.sessao)),
    };

    const options = {
      method,
      headers,
      body: data ? JSON.stringify(data) : null,
    };

    try {
      const response = await fetch(`${URL_API}${url}`, options);

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error(error);
      return { status: false, message: error.message };
    }
  }

  async get(url) {
    return await this.request(url, "GET");
  }

  async post(url, data) {
    return await this.request(url, "POST", data);
  }

  async put(url, data) {
    return await this.request(url, "PUT", data);
  }

  async delete(url) {
    return await this.request(url, "DELETE");
  }

  /*
  AUTH  
  */
  async logando(data) {
    return this.post("/api/auth/logando", data);
  }
  async criandoConta(data) {
    return this.post("/api/auth/criar-conta", data);
  }

  async getSessao() {
    return this.get("/api/auth/sessao");
  }
  /*
  HOME 
  */
  async relatorioHome() {
    console.log("buscando relatorio home...");
    return this.get("/api/home/relatorio");
  }

  /*
  USUARIO 
  */
  async clientes() {
    console.log("buscando clientes...");
    return this.get("/api/usuario/clientes");
  }
  async meuPerfil() {
    console.log("buscando clientes...");
    return this.get("/api/usuario/perfil");
  }
  async colaboradores() {
    console.log("buscando colaboradores...");
    return this.get("/api/usuario/colaboradores");
  }
  async buscandoUsuarioPorNome(nome) {
    console.log("buscando por nome ...");
    return this.post("/api/usuario/buscar-pelo-nome-e-empresa", { nome: nome });
  }

  async atualizarUsuario(data) {
    return this.post("/api/usuario/atualizar", data);
  }

  async atualizarPerfil(data) {
    return this.post("/api/usuario/atualizar-perfil", data);
  }
  async cadastrarUsuario(data) {
    return this.post("/api/usuario/cadastrar", data);
  }

  /*
  PROJETO 
  */
  async adicionandoUsuarioProjeto(data) {
    console.log("adicionar usuario projeto ...");
    return this.post("/api/projeto/adicionar-usuario", data);
  }

  async projetos() {
    console.log("buscando projetos...");
    return this.get("/api/projeto/todos");
  }
  async detalheProjeto(id) {
    console.log("buscando projeto...");
    return this.get("/api/projeto/detalhe/" + id);
  }

  async novoProjeto() {
    return this.post("/api/projeto/cadastrar");
  }
  async removendoUsuarioDoProjeto(user_id, projeto_id) {
    return this.post("/api/projeto/remover-usuario", { user_id, projeto_id });
  }

  /*
   ARQUIVO 
   */
  async deletarArquivo(id) {
    return this.delete("/api/arquivo/" + id);
  }
}

export default CustomAPI;
