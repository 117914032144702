import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

// Data
import Data from "layouts/projects/data/index";
import TebaleCarregando from "layouts/projects/data/carregando";
import { useAuth } from "context/AuthContext";
import TabelaProjetos from "layouts/tables/projetos";
import CustomAPI from "../../CustomAPI";
import PagarLiberar from "components/PagarLiberar";

function Projetos() {
  console.log("Pagina Projetos");
  const navigate = useNavigate();
  const [carregando, setCarregando] = useState(true);
  const [is_admin, setIsadmin] = useState(false);
  const [criandoNovoProjeto, setCriandoNovoProjeto] = useState(false);
  const [alertarPagar, setAlertaPagar] = useState(false);

  const [projetos, setProjetos] = useState([]);

  const { usuario, Logout, sessao } = useAuth();

  useEffect(() => {
    console.log("=>", projetos);
  }, [projetos]);

  useEffect(() => {
    if (usuario?.empresa?.is_admin === "sim") {
      setIsadmin(true);
      if (
        usuario.empresa.empresa_situacao === "aguardando" ||
        usuario.empresa.empresa_situacao === "desativada"
      ) {
        setAlertaPagar(true);
        return;
      }
    }
    const fetch = async () => {
      try {
        const api = new CustomAPI(sessao);
        const resultado = await api.projetos();
        if (!resultado.status && resultado.sessao_expirada) {
          Logout();
          return;
        }
        setProjetos(resultado?.data?.projetos);
        setCarregando(false);
      } catch (error) {
        console.error("Ocorreu um erro:", error);
      }
    };

    if (usuario?.empresa) {
      fetch();
    }
  }, [usuario]);

  function Tabela(projeto) {
    console.log(":", projeto);
    if (projeto && projeto.data.length > 0) {
      const { columns, rows } = Data(projeto);
    }
  }

  async function novoProjeto() {
    setCriandoNovoProjeto(true);
    try {
      if (sessao) {
        const api = new CustomAPI(sessao);
        const resultado = await api.novoProjeto();
        if (!resultado.status && resultado.sessao_expirada) {
          Logout();
          return;
        }
        setCriandoNovoProjeto(false);
        navigate(`/projetos/${resultado?.projeto_id}`);
      }
    } catch (error) {
      setCriandoNovoProjeto(false);
      console.error("Ocorreu um erro:", error);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={5}>
        <Grid container spacing={3} mb={2}>
          {alertarPagar && <PagarLiberar />}
          <Grid item xs={12}>
            <Card key={"pagina-projetos"}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  <Icon fontSize="small">architecture</Icon> Projetos
                </MDTypography>
                {is_admin && (
                  <MDButton
                    style={{ position: "absolute", float: "right", right: "27px", top: "-7px" }}
                    variant="gradient"
                    type="submit"
                    color="dark"
                    endIcon={<Icon>add</Icon>}
                    onClick={() => novoProjeto()}
                    disabled={criandoNovoProjeto || alertarPagar}
                  >
                    {criandoNovoProjeto ? "CRIANDO..." : "NOVO PROJETO"}
                  </MDButton>
                )}
              </MDBox>
              <MDBox pt={3}>
                {carregando ? (
                  <TebaleCarregando />
                ) : (
                  <TabelaProjetos data={projetos} Atualizar={() => console.log("ok")} />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Projetos;
