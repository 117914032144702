/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Alert from "@mui/material/Alert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import TebaleCarregando from "layouts/projects/data/carregando";
import PagarLiberar from "components/PagarLiberar";
// Dashboard components
import Projects from "layouts/projects";
import Skeleton from "@mui/material/Skeleton";
import { useAuth } from "context/AuthContext";
import CustomAPI from "CustomAPI";

function Dashboard() {
  const [carregando, setCarregando] = useState(true);
  const [is_admin, setIsadmin] = useState(false);
  const [total, setTotal] = useState({});
  const [projetosRecentes, setProjetosRecentes] = useState([]);
  const [alertarPagar, setAlertaPagar] = useState(false);
  const [bloco, setBloco] = useState(6);
  const { usuario, Logout, sessao } = useAuth();

  useEffect(() => {
    //console.log("USER=>", usuario);
    const fetchHomeData = async () => {
      try {
        if (usuario?.empresa?.is_admin === "sim") {
          if (
            usuario.empresa.empresa_situacao === "aguardando" ||
            usuario.empresa.empresa_situacao === "desativada"
          ) {
            setAlertaPagar(true);
            return;
          }
          setIsadmin(true);
          setBloco(4);
        }
        const api = new CustomAPI(sessao);
        const homeResult = await api.relatorioHome();
        if (!homeResult.status && homeResult.sessao_expirada) {
          Logout();
          return;
        }
        setTotal(homeResult.total);
        setProjetosRecentes(homeResult?.projetos?.projetos);
        setCarregando(false);
      } catch (error) {
        console.error("Ocorreu um erro:", error);
      }
    };

    if (usuario?.empresa) {
      fetchHomeData();
    }
  }, [usuario]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3} mb={2}>
          {alertarPagar && <PagarLiberar />}
          <Grid item xs={12} md={bloco}>
            {carregando ? (
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: 3 }}
                height={120}
                animation="wave"
              />
            ) : (
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="architecture"
                  title="Projetos"
                  count={total?.projeto_desenvolvimento}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Desenvolvimento",
                  }}
                />
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} md={bloco}>
            {carregando ? (
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: 3 }}
                height={120}
                animation="wave"
              />
            ) : (
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="architecture"
                  title="Projetos"
                  count={total?.projeto_finalizado}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Finalizado",
                  }}
                />
              </MDBox>
            )}
          </Grid>
          {is_admin && (
            <Grid item xs={12} md={bloco}>
              {carregando ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 3 }}
                  height={120}
                  animation="wave"
                />
              ) : (
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="person_add"
                    title="Clientes"
                    count={total?.cliente}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "Todos os clientes",
                    }}
                  />
                </MDBox>
              )}
            </Grid>
          )}
        </Grid>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              {carregando ? (
                <TebaleCarregando />
              ) : (
                <Projects
                  key={projetosRecentes}
                  data={projetosRecentes}
                  Atualizar={() => console.log("ok")}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {carregando ? "" : ""}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
