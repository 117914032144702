/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { URL_API } from "config";

import Usuario from "layouts/cadastrar/usuario";

export default function Tabela(usuarios) {
  const [openEditar, setOpenEditar] = useState(false);
  const [usuarioEditar, setUsuarioEditar] = useState([]);

  console.log("clientes=>", usuarios);

  function data(clientes) {
    const rows = [];
    const Author = ({ image, name, email }) => (
      <MDBox key={email} display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar src={image} name={name} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
      </MDBox>
    );

    function situacao(s) {
      if (s == 1) {
        return <MDBadge badgeContent="ativo" color="success" variant="gradient" size="sm" />;
      } else {
        return <MDBadge badgeContent="desativado" color="error" variant="gradient" size="sm" />;
      }
    }

    function modelo(cliente) {
      return {
        author: (
          <Author
            image={`${URL_API}/${cliente?.user_image}`}
            name={cliente?.user_nome}
            email={cliente?.user_email}
          />
        ),
        status: <MDBox ml={-1}>{situacao(cliente?.user_situacao)}</MDBox>,
        action: (
          <IconButton
            aria-label="editar"
            onClick={() => {
              setUsuarioEditar(cliente);
              setOpenEditar(true);
            }}
          >
            <EditIcon />
          </IconButton>
        ),
      };
    }

    clientes?.map((cliente) => {
      rows.push(modelo(cliente));
    });
    return {
      columns: [
        { Header: "Nome", accessor: "author", align: "left" },
        { Header: "Status", accessor: "status", width: 50, align: "center" },
        { Header: "Ação", accessor: "action", width: 50, align: "center" },
      ],
      rows: rows,
    };
  }
  const { columns, rows } = data(usuarios.usuarios);
  return (
    <>
      {openEditar && (
        <Usuario Close={() => setOpenEditar(false)} usuario={2} tipo={2} dados={usuarioEditar} />
      )}

      <DataTable
        key={`clientes-${usuarios.usuarios}`}
        table={{ columns, rows }}
        canSearch={true}
        pagination={true}
        isSorted={true}
        entriesPerPage={true}
        showTotalEntries={true}
        noEndBorder
      />
    </>
  );
}
