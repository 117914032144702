// @mui material components
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { Card } from "@mui/material";

export default function PagarLiberar() {
  const navigate = useNavigate();

  const { usuario } = useAuth();
  const [mensagem, setMensagem] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const nome = usuario?.nome?.split(" ")[0];
    console.log("usuario", usuario);
    let msg = (
      <>
        Olá {nome}! Falta pouco para ativar sua empresa {""}
        <strong>{usuario?.empresa?.empresa_nome}</strong> Experimente <strong>07</strong> dias
        Grátis !
      </>
    );
    setMensagem(msg);
  }, []);
  const handleClose = () => {
    setOpen(false);
  };

  function openlink(link) {
    window.location.href = link;
  }

  return (
    <>
      <Grid item xs={12} mb={2}>
        <Alert variant="outlined" severity="info" sx={{ background: "#fff" }}>
          {mensagem}{" "}
          <MDButton
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => openlink(usuario?.pay_token?.stripe_url)}
            endIcon={<Icon>add_shopping_cart</Icon>}
            disabled={!usuario?.pay_token?.stripe_url}
          >
            {usuario?.pay_token?.stripe_url ? "ATIVAR AGORA" : "ERRO INTERNO"}
          </MDButton>
        </Alert>
      </Grid>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle textAlign={"center"}>ATIVAR {usuario?.empresa?.empresa_nome}</DialogTitle>
        <DialogContent>
          <Grid container padding={2} spacing={2}>
            <Grid xs={12} md={12} mb={1}>
              <Card sx={{ alignItems: "center" }}>
                <stripe-buy-button
                  customer-id={usuario?.stripe}
                  customer-email={usuario.email}
                  client-reference-id={usuario?.empresa?.empresa_id}
                  buy-button-id={usuario?.pay_token?.STRIPE_BUTTON_ID}
                  publishable-key={usuario?.pay_token?.STRIPE_PUBLISHABLE_KEY}
                ></stripe-buy-button>
              </Card>
            </Grid>
            <Grid xs={12} md={12} mb={1}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
