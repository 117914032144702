import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { MuiTelInput } from "mui-tel-input";
import InputMask from "react-input-mask";
import { TextField } from "@mui/material";
import { gerarNumerosAleatorios, consultarCEP } from "components/funcoes";
import { useAuth } from "context/AuthContext";

import CustomAPI from "../../CustomAPI";

import Alertas from "components/Alerta";
// eslint-disable-next-line react/prop-types
export default function Usuario({ Close, tipo, usuarioTipo, dados }) {
  console.log("dados", dados);
  const [showPassword, setShowPassword] = React.useState(false);
  const [salvando, setSalvando] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState(dados);
  const [atualizarCadastrar, setAtualizarCadastrar] = React.useState(tipo);

  const [alertas, setAlerta] = React.useState({ show: false, message: "", type: "success" });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const { usuario, Logout, sessao } = useAuth();

  const handleClose = () => {
    setOpen(false);
    Close(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSalvando(true);
    const api = new CustomAPI(sessao);
    if (atualizarCadastrar === 1) {
      const salvando = await api.cadastrarUsuario(data);
      if (salvando.status) {
        setAtualizarCadastrar(2);
        setData({ ...data, user_id: salvando.user_id });
        setAlerta({ show: true, message: "Cadastrado com sucesso", type: "success" });
      } else {
        setAlerta({ show: true, message: salvando.message, type: "error" });
      }
    } else if (atualizarCadastrar === 2) {
      const salvando = await api.atualizarUsuario(data);
      if (salvando.status) {
        setAlerta({ show: true, message: "Atualizado com sucesso", type: "success" });
      } else {
        setAlerta({ show: true, message: salvando.message, type: "error" });
      }
    }
    setSalvando(false);
  };

  async function consultandoCEP(cep) {
    setData({ ...data, user_end_cep: cep });
    if (cep?.length === 9) {
      try {
        let logradouro = "";
        let complemento = "";
        let bairro = "";
        const resultado = await consultarCEP(cep);
        if (resultado?.logradouro) {
          logradouro = `${resultado?.logradouro}, `;
        }
        if (resultado?.complemento) {
          complemento = `${resultado?.complemento}, `;
        }
        if (resultado?.bairro) {
          bairro = `${resultado?.bairro}`;
        }
        setData({
          ...data,
          user_end_cep: cep,
          user_end_complemento: `${logradouro}${complemento}${bairro}`,
          user_end_uf: resultado?.uf,
          user_end_cidade: resultado?.localidade,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
  const formatCpf = (value) => {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const formatCnpj = (value) => {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  };

  const mask = (value) => {
    if (value && value.length === 11) {
      return formatCpf(value);
    } else if (value && value.length === 14) {
      return formatCnpj(value);
    }
  };

  const handleChangeCPF = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "");
    event.target.value = numericValue;
    if (numericValue.length === 11) {
      event.target.value = formatCpf(numericValue);
    } else if (numericValue.length === 14) {
      event.target.value = formatCnpj(numericValue);
    }
    setData({ ...data, user_cpf_cnpj: event.target.value });
  };

  React.useEffect(() => {
    if (!data?.user_senha) {
      console.log("usuarioTipo", usuarioTipo);
      if (usuarioTipo === 1) {
        setData({ ...data, is_cliente: true });
      }
      if (usuarioTipo === 2) {
        setData({ ...data, is_colaborador: true });
      }
      setData({ ...data, user_senha: gerarNumerosAleatorios() });
    }
  }, [data]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle>
          [{atualizarCadastrar === 1 && "CADASTRANDO"}
          {atualizarCadastrar === 2 && "EDITANDO"}]
        </DialogTitle>
        <DialogContent fullWidth>
          <MDBox
            pt={1}
            component="form"
            role="form"
            noValidate={false}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            {alertas.show && (
              <Alertas
                message={alertas.message}
                severity={alertas.type}
                Close={() => setAlerta({ ...alertas, show: false })}
              />
            )}

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} mb={1}>
                <MDInput
                  value={data?.user_nome}
                  type="text"
                  name="user_nome"
                  onChange={(event) => setData({ ...data, user_nome: event.target.value })}
                  label="Nome completo"
                  required={true}
                  fullWidth
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} md={4} mb={1}>
                <MDInput
                  defaultValue={mask(data?.user_cpf_cnpj)}
                  value={data?.user_cpf_cnpj}
                  onChange={handleChangeCPF}
                  type="text"
                  name="user_cpf_cnpj"
                  label="CPF/CNPJ"
                  required={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2} mb={1}>
                <FormControl fullWidth>
                  <InputLabel id="genero">Genero</InputLabel>
                  <Select
                    defaultValue={data?.user_genero}
                    name="user_genero"
                    onChange={(event) => setData({ ...data, user_genero: event.target.value })}
                    sx={{ height: 45 }}
                    labelId="genero"
                    label="Gênero"
                  >
                    <MenuItem value={"M"}>M</MenuItem>
                    <MenuItem value={"F"}>F</MenuItem>
                    <MenuItem value={"O"}>O</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5} mb={1}>
                <FormControl variant="outlined" fullWidth>
                  <MuiTelInput
                    fullWidth
                    label="Número de celular"
                    name="user_celular"
                    required
                    defaultCountry={"BR"}
                    value={data?.user_celular}
                    onChange={(e) => setData({ ...data, user_celular: e })}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={7} mb={1}>
                <MDInput
                  value={data?.user_email}
                  type="email"
                  name="user_email"
                  onChange={(event) => setData({ ...data, user_email: event.target.value })}
                  label="E-mail"
                  required={true}
                  autoComplete="off"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} mb={1}>
                <InputMask
                  mask="99999-999"
                  maskChar={null}
                  value={data?.user_end_cep}
                  onChange={(event) => consultandoCEP(event?.target?.value)}
                >
                  {() => (
                    <TextField
                      autoComplete="nope"
                      name="user_end_cep"
                      label="CEP"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} md={6} mb={1}>
                <MDInput
                  type="text"
                  name="user_end_cidade"
                  label="Cidade"
                  onChange={(event) => setData({ ...data, user_end_cidade: event?.target?.value })}
                  value={data?.user_end_cidade}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} mb={1}>
                <MDInput
                  type="text"
                  name="user_end_uf"
                  label="UF"
                  value={data?.user_end_uf}
                  onChange={(event) => setData({ ...data, user_end_uf: event?.target?.value })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12} mb={1}>
                <MDInput
                  type="text"
                  name="user_end_complemento"
                  defaultValue={data?.user_end_complemento}
                  value={data?.user_end_complemento}
                  onChange={(event) =>
                    setData({ ...data, user_end_complemento: event.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Complemento"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5} mb={1}>
                [Permissões]
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={data?.is_cliente === "sim" || data?.is_cliente === true}
                      name="cliente"
                      onChange={(event) =>
                        setData({ ...data, is_cliente: event.target.checked ? "sim" : "nao" })
                      }
                    />
                  }
                  label="Cliente"
                />
                <FormControlLabel
                  control={
                    <Switch
                      name="colaborador"
                      defaultChecked={
                        data?.is_colaborador === "sim" || data?.is_colaborador === true
                      }
                      onChange={(event) =>
                        setData({ ...data, is_colaborador: event.target.checked ? "sim" : "nao" })
                      }
                    />
                  }
                  label="Colaborador(a)"
                />
                {data?.is_colaborador === true ||
                  (data?.is_colaborador === "sim" && (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={
                              data?.is_projetos === "sim" || data?.is_projetos === true
                            }
                            onChange={(event) =>
                              setData({
                                ...data,
                                is_projetos: event.target.checked ? "sim" : "nao",
                              })
                            }
                            name="setor_projeto"
                          />
                        }
                        label="Projetos"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={
                              data?.is_financeiro === "sim" || data?.is_financeiro === true
                            }
                            onChange={(event) =>
                              setData({
                                ...data,
                                is_financeiro: event.target.checked ? "sim" : "nao",
                              })
                            }
                            name="financeiro"
                          />
                        }
                        label="Financeiro"
                      />
                    </>
                  ))}
              </Grid>

              <Grid item xs={12} md={7} mb={1}>
                {atualizarCadastrar === 1 && (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Senha de acesso</InputLabel>
                    <OutlinedInput
                      name="user_senha"
                      value={data?.user_senha}
                      onChange={(event) => setData({ ...data, user_senha: event.target.value })}
                      required={true}
                      label="Senha de acesso"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      fullWidth
                    />
                  </FormControl>
                )}

                {atualizarCadastrar === 2 && (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Nova Senha</InputLabel>
                    <OutlinedInput
                      key={"novasenha"}
                      name="user_senha"
                      onChange={(event) =>
                        setData({ ...data, user_nova_senha: event.target.value })
                      }
                      label="Nova Senha"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      fullWidth
                    />
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={12} md={12} mb={1}>
                <MDButton
                  variant="gradient"
                  type="submit"
                  color="success"
                  endIcon={<Icon>send</Icon>}
                  disabled={salvando}
                >
                  {salvando ? "SALVANDO..." : "SALVAR"}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
