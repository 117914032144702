/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as React from "react";
import { useState } from "react";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Switch from "@mui/material/Switch";
import { MuiTelInput } from "mui-tel-input";
import Grid from "@mui/material/Grid";
import InputMask from "react-input-mask";
import Icon from "@mui/material/Icon";

import CustomAPI from "CustomAPI";

import { consultarCEP } from "components/funcoes";
import Alertas from "components/Alerta";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/authentication/components/BasicLayout";

import bgImage from "assets/images/fundo-b.jpeg";

import { useAuth } from "context/AuthContext";

const steps = ["01", "02", "03", "04", "05"];

function Registrar() {
  const { authenticated, Login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [data, setData] = React.useState({});
  const [salvando, setSalvando] = useState(false);
  const [alertas, setAlerta] = React.useState({ show: false, message: "", type: "success" });
  const [termos, setAceitarTermos] = useState(false);

  const navigate = useNavigate();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    console.log("newActiveStep", newActiveStep);
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSalvando(true);
    const api = new CustomAPI({ sessao: "nope" });
    const criando = await api.criandoConta(data);
    setSalvando(false);
    if (criando.status) {
      setAlerta({ show: true, message: "Criado com sucesso", type: "success" });
      Login(criando.sessao);
      navigate("/dashboard");
    } else {
      setAlerta({ show: true, message: criando.message, type: "error" });
    }
  };

  async function consultandoCEP(cep) {
    setData({ ...data, user_end_cep: cep });
    if (cep?.length === 9) {
      try {
        let logradouro = "";
        let complemento = "";
        let bairro = "";
        const resultado = await consultarCEP(cep);
        if (resultado?.logradouro) {
          logradouro = `${resultado?.logradouro}, `;
        }
        if (resultado?.complemento) {
          complemento = `${resultado?.complemento}, `;
        }
        if (resultado?.bairro) {
          bairro = `${resultado?.bairro}`;
        }
        setData({
          ...data,
          user_end_cep: cep,
          user_end_complemento: `${logradouro}${complemento}${bairro}`,
          user_end_uf: resultado?.uf,
          user_end_cidade: resultado?.localidade,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  function consultandoCNPJ(cnpj) {
    console.log(cnpj.length);
    setData({ ...data, empresa_cnpj: cnpj });
    if (cnpj.length === 18) {
      const url = `https://minhareceita.org/${cnpj.replace(/\D/g, "")}`;
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((e) => {
          if (e?.razao_social) {
            setData({
              ...data,
              empresa_cnpj: cnpj,
              user_end_complemento: `${e?.logradouro}, ${e?.bairro}, ${e?.numero}`,
              user_end_cidade: e?.municipio,
              user_end_cep: e?.cep,
              empresa_nome: e?.razao_social,
              user_end_uf: e?.uf,
              user_nome: e?.qsa[0].nome_socio,
            });
          }
        })
        .catch((error) => {
          console.error("Erro:", error.message);
        });
    }
  }

  React.useEffect(() => {
    if (authenticated) {
      navigate("/dashboard");
    }
  }, [authenticated]);

  return (
    <BasicLayout image={bgImage}>
      {alertas.show && (
        <Alertas
          message={alertas.message}
          severity={alertas.type}
          Close={() => setAlerta({ ...alertas, show: false })}
        />
      )}
      <Card fullWidth>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-4}
          p={5}
          mb={2}
          textAlign="center"
        >
          <MDTypography variant="h5" fontWeight="medium" color="black" mt={1}>
            REGISTRE-SE
          </MDTypography>
          <MDTypography style={{ fontSize: 12 }} variant="span" color="red" mt={1}>
            Cadastro Reservado para Empresários(a)
          </MDTypography>
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox
            component="form"
            role="form"
            noValidate={false}
            onSubmit={handleSubmit}
            autoComplete="nope"
          >
            {activeStep === 1 && (
              <>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    name="user_nome"
                    value={data?.user_nome}
                    onChange={(event) => setData({ ...data, user_nome: event?.target?.value })}
                    label="Nome completo"
                    autoComplete="off"
                    required={true}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <InputMask
                    mask="999.999.999-99"
                    maskChar={null}
                    value={data?.user_cpf_cnpj}
                    onChange={(event) => setData({ ...data, user_cpf_cnpj: event?.target?.value })}
                  >
                    {() => (
                      <TextField
                        type="tel"
                        autoComplete="nope"
                        name="user_cpf_cnpj"
                        value={data?.user_cpf_cnpj}
                        label="CPF"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  </InputMask>
                </MDBox>
                <MDBox mb={2}>
                  <FormControl variant="outlined" fullWidth>
                    <MuiTelInput
                      fullWidth
                      autoComplete="off"
                      label="Número de celular"
                      name="user_celular"
                      required
                      defaultCountry={"BR"}
                      value={data?.user_celular}
                      onChange={(e) => setData({ ...data, user_celular: e })}
                      variant="outlined"
                    />
                  </FormControl>
                </MDBox>
              </>
            )}
            {activeStep === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12} mb={1}>
                  <InputMask
                    mask="99.999.999/9999-99"
                    maskChar={null}
                    value={data?.empresa_cnpj}
                    onChange={(event) => consultandoCNPJ(event?.target?.value)}
                  >
                    {() => (
                      <TextField
                        type="tel"
                        autoComplete="nope"
                        name="empresa_cnpj"
                        value={data?.empresa_cnpj}
                        label="CNPJ da Empresa"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} mb={1}>
                  <MDInput
                    type="text"
                    name="empresa_nome"
                    value={data?.empresa_nome}
                    onChange={(event) => setData({ ...data, empresa_nome: event?.target?.value })}
                    label="Nome da Empresa"
                    autoComplete="off"
                    required={true}
                    fullWidth
                    InputLabelProps={{
                      shrink: data?.empresa_nome,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5} mb={1}>
                  <InputMask
                    mask="99999-999"
                    maskChar={null}
                    autoComplete="off"
                    value={data?.user_end_cep}
                    onChange={(event) => consultandoCEP(event?.target?.value)}
                  >
                    {() => (
                      <TextField
                        type="tel"
                        autoComplete="off"
                        name="user_end_cep"
                        label="CEP"
                        variant="outlined"
                        fullWidth
                        required
                        InputLabelProps={{
                          shrink: data?.user_end_cep,
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} md={7} mb={1}>
                  <MDInput
                    type="text"
                    name="user_end_cidade"
                    value={data?.user_end_cidade}
                    onChange={(event) =>
                      setData({ ...data, user_end_cidade: event?.target?.value })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Cidade"
                    autoComplete="off"
                    required={true}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
            {activeStep === 2 && (
              <>
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    name="user_email"
                    value={data?.user_email}
                    onChange={(event) => setData({ ...data, user_email: event?.target?.value })}
                    label="E-mail"
                    autoComplete="off"
                    required={true}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                    <OutlinedInput
                      name="user_senha"
                      required={true}
                      value={data?.user_senha}
                      onChange={(event) => setData({ ...data, user_senha: event?.target?.value })}
                      label="Senha"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      fullWidth
                    />
                  </FormControl>
                </MDBox>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Switch onClick={() => setAceitarTermos(!termos)} />
                  &nbsp;&nbsp;
                  <MDTypography mt={0} fontSize={12} variant="span" color="text">
                    Eu aceito os Termos de Uso
                  </MDTypography>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    type="submit"
                    color="success"
                    fullWidth
                    endIcon={<Icon>send</Icon>}
                    disabled={salvando || !termos}
                  >
                    {salvando ? "Criando..." : "Criar conta"}
                  </MDButton>
                </MDBox>
              </>
            )}
            <div>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Voltar
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep !== 2 && (
                  <Button
                    onClick={handleNext}
                    sx={{ mr: 1 }}
                    disabled={
                      (activeStep === 0 && !data.empresa_cnpj) ||
                      (activeStep === 0 && !data.empresa_nome) ||
                      (activeStep === 0 && !data.user_end_cep) ||
                      (activeStep === 1 && !data.user_nome) ||
                      (activeStep === 1 && !data.user_cpf_cnpj) ||
                      (activeStep === 1 && !data.user_celular)
                    }
                  >
                    Próxima
                  </Button>
                )}
              </Box>
            </div>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Já tenho uma conta{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Acessar
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Registrar;
