/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link, useParams, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import { Document, Page } from "react-pdf";
import PDFViewer from "pdf-viewer-reactjs";
import DataTable from "examples/Tables/DataTable";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "components/MDButton";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Data from "../../layouts/projects/data/usuariosProjeto";
import ModalRelacionarUsuarioNoProjeto from "../../components/ModalRelacinoarUsuarioProjeto";
// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import breakpoints from "assets/theme/base/breakpoints";
import Skeleton from "@mui/material/Skeleton";
import { gerandoToken } from "components/funcoes";
import { useAuth } from "context/AuthContext";
import CustomAPI from "../../CustomAPI";
import ModalVideo from "components/ModalVideo";
import "assets/css/style.css";
import { PdfThumbnail, ImageThumbnail, VideoThumbnail } from "components/funcoes";
import { CircularWithValueLabel } from "components/progresso";
import { URL_API } from "config";
import AbrirPDF from "components/AbrirPDF";
import TabelaUsuariosProjeto from "../../layouts/projects/data/usuariosProjeto";
function Projeto() {
  const navigate = useNavigate();
  const { projeto_id } = useParams();
  const { usuario, Logout, sessao } = useAuth();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [is_admin, setIsAdmin] = useState(false);
  const [is_colaborador, setIsColaborador] = useState(false);
  const [is_cliente, setIsCliente] = useState(false);
  const [modalAbrirImagens, setModalAbrirImagens] = useState(false);
  const [modalAbrirPDF, setModalAbrirPDF] = useState(false);
  const [modalAbrirVideo, setModalAbrirVideo] = useState(false);
  const [modalAddUsuarioProjeto, setModalAddUsuarioProjeto] = useState(false);
  const [views, setViews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [projeto, setProjeto] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [selectedFile, setSelectedFile] = useState([]);

  const handleFileDrop = async (acceptedFiles) => {
    let tipo_arquivo = "pdf";
    if (tabValue === 2) {
      tipo_arquivo = "imagem";
    }
    if (tabValue === 3) {
      tipo_arquivo = "video";
    }
    if (acceptedFiles && acceptedFiles.length > 0) {
      const filesWithThumbnails = await Promise.all(
        acceptedFiles.map(async (file) => {
          let thumbnail;
          if (tipo_arquivo === "pdf") {
            thumbnail = await PdfThumbnail(URL.createObjectURL(file));
          }
          if (tipo_arquivo === "imagem") {
            thumbnail = await ImageThumbnail(file);
          }
          if (tipo_arquivo === "video") {
            thumbnail = await VideoThumbnail(file);
            console.log("thumbnail", thumbnail);
          }
          return {
            id: gerandoToken(),
            file: file,
            arquivo_nome: file.name,
            name: file.name,
            url: URL.createObjectURL(file),
            thumbnail: thumbnail,
            percentage: 1,
            uploaded: false,
            tipo_arquivo: tipo_arquivo,
            status: true,
          };
        })
      );
      const arrayFiles = [...selectedFile, ...filesWithThumbnails];
      setSelectedFile(arrayFiles);
      await handleUpload(arrayFiles, tipo_arquivo);
    }
  };

  const handleUpload = async (arquivos, type) => {
    const api = new CustomAPI(sessao);
    if (arquivos && arquivos.length > 0) {
      arquivos.forEach(async (file, index) => {
        if (!file.uploaded) {
          const id_arquivo = file.id;
          const formData = new FormData();
          formData.append("type", type);
          formData.append("thumbnail", file?.thumbnail);
          formData.append("projeto_id", projeto_id);
          formData.append("file", file.file);
          api.updatedFile(
            formData,
            (percentage) => {
              console.log(`Porcentagem de upload: ${percentage}%`);
              setSelectedFile((prevSelectedFile) => {
                const updatedFile = [...prevSelectedFile];
                const fileIndex = updatedFile.findIndex((f) => f.id === id_arquivo);
                if (fileIndex !== -1) {
                  updatedFile[index].percentage = percentage;
                }
                return updatedFile;
              });
            },
            (response) => {
              console.log("Resposta do servidor:", response);
              setSelectedFile((prevSelectedFile) => {
                const updatedFile = [...prevSelectedFile];
                const fileIndex = updatedFile.findIndex((f) => f.id === id_arquivo);
                if (fileIndex !== -1) {
                  updatedFile[fileIndex].id = response?.id;
                  updatedFile[fileIndex].status = response.status;
                  updatedFile[fileIndex].uploaded = true;
                  updatedFile[fileIndex].mensagem = response?.message;
                }
                return updatedFile;
              });
            }
          );
        }
      });
    }
  };

  useEffect(() => {
    console.log("tabsOrientation", tabsOrientation);
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    const fetchData = async () => {
      if (usuario?.empresa) {
        if (usuario.empresa.is_admin === "sim") {
          setIsAdmin(true);
        }
        if (usuario.empresa.is_colaborador === "sim") {
          setIsColaborador(true);
        }
        if (usuario.empresa.is_cliente === "sim") {
          setIsCliente(true);
        }
        await detalhe();
      }
    };
    fetchData();
  }, [usuario]);

  useEffect(() => {
    if (projeto && projeto?.membros?.length === 0) {
      if (!is_admin) {
        navigate("/projetos");
        return;
      }
    }
  }, [projeto]);

  async function detalhe() {
    const api = new CustomAPI(sessao);
    const resultado = await api.detalheProjeto(projeto_id);
    if (resultado.status) {
      if (resultado?.data?.projeto) {
        setProjeto(resultado.data);
        if (resultado?.data?.arquivos?.length > 0) {
          const files = [];
          resultado?.data?.arquivos.forEach((file) => {
            const a = { ...file, uploaded: true, url: `${URL_API}/${file.arquivo_url}` };
            files.push(a);
          });
          setSelectedFile(files);
        }
        setCarregando(false);
      }
    } else {
      navigate("/projetos");
    }
  }

  function TabDetalhe() {
    console.log("Tab Detalhe");
    return (
      <Grid container>
        <Grid item xs={12}>
          <Card></Card>
        </Grid>
      </Grid>
    );
  }

  async function removendoArquivo(id) {
    const files = selectedFile.filter((file) => file.id !== id);
    setSelectedFile(files);
    const file = selectedFile.filter((file) => file.id === id);
    if (file) {
      if (file[0].uploaded) {
        const api = new CustomAPI(sessao);
        const removendo = await api.deletarArquivo(id);
      }
    }
  }

  function botaoRemoverArquivo(id) {
    return (
      <IconButton
        onClick={() => removendoArquivo(id)}
        className="botao-remover-arquivo"
        color="error"
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
    );
  }

  function mensagemErroUpload(msg) {
    return <span className="erro-upload">{msg}</span>;
  }

  function TabeFile(type, accept, Open) {
    console.log(`Tab file ${type}`);
    const files = selectedFile?.filter((file) => file.tipo_arquivo === type);
    const a = [];
    files.map((e) => {
      a.push({ caption: e.arquivo_nome, alt: e.arquivo_nome, source: e.url });
    });
    return (
      <Grid container padding={2} spacing={3} key={files}>
        {(is_admin || is_colaborador) && (
          <Grid item xs={12} md={4}>
            <div className="image-upload-container">
              <Dropzone onDrop={handleFileDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} accept={accept} multiple />
                    <p>
                      Arraste e solte <b>{type.toUpperCase()}</b> ou clique para selecionar.
                    </p>
                  </div>
                )}
              </Dropzone>
            </div>
          </Grid>
        )}
        <Grid item xs={12} md={(is_admin || is_colaborador) && 8}>
          {files.length > 0 && (
            <Grid container padding={2} spacing={2}>
              {files.map((file, index) => (
                <Grid item xs={7} md={3} key={index} mb={1}>
                  <div className="selected-image">
                    <Tooltip
                      key={file?.arquivo_nome}
                      title={file?.arquivo_nome}
                      placeholder="bottom"
                    >
                      <img
                        onClick={() => Open(a, index)}
                        src={file.thumbnail}
                        title={file?.arquivo_nome}
                        alt={file?.arquivo_nome}
                        key={file.url}
                      />
                    </Tooltip>
                    {(is_admin || is_colaborador) && file.uploaded && botaoRemoverArquivo(file.id)}
                    {!file.status && mensagemErroUpload(file.mensagem)}
                    {!file.uploaded && (
                      <div className="percentage">{CircularWithValueLabel(file.percentage)}</div>
                    )}
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12}>
            <Card style={{ minHeight: "80vh" }}>
              <MDBox pt={3} padding={2}>
                <Grid item xs={12} mb={2}>
                  <MDTypography variant="h6" fontWeight="medium">
                    {carregando ? (
                      <Skeleton width={100} animation="wave" />
                    ) : (
                      `Projeto: ${projeto?.projeto[0]?.projeto_nome}`
                    )}
                  </MDTypography>
                </Grid>
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                  className="tabs-proejto"
                >
                  <Tab
                    disabled={carregando}
                    label={carregando ? <Skeleton width={100} animation="wave" /> : "Detalhes"}
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        architecture
                      </Icon>
                    }
                  />
                  <Tab
                    disabled={carregando}
                    label={carregando ? <Skeleton width={100} animation="wave" /> : "PDF"}
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        picture_as_pdf
                      </Icon>
                    }
                  />
                  <Tab
                    disabled={carregando}
                    label={carregando ? <Skeleton width={100} animation="wave" /> : "Fotos"}
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        image
                      </Icon>
                    }
                  />
                  <Tab
                    disabled={carregando}
                    label={carregando ? <Skeleton width={100} animation="wave" /> : "Vídeos"}
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        ondemand_video
                      </Icon>
                    }
                  />
                  <Tab
                    disabled={carregando}
                    label={carregando ? <Skeleton width={100} animation="wave" /> : "Mensagens"}
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        forum
                      </Icon>
                    }
                  />
                  {(is_admin || is_colaborador) && (
                    <Tab
                      disabled={carregando}
                      label={carregando ? <Skeleton width={100} animation="wave" /> : "Usuários"}
                      icon={
                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                          group
                        </Icon>
                      }
                    />
                  )}
                  {(is_admin || is_colaborador) && (
                    <Tab
                      disabled={carregando}
                      label={
                        carregando ? <Skeleton width={100} animation="wave" /> : "Configurações"
                      }
                      icon={
                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                          settings
                        </Icon>
                      }
                    />
                  )}
                </Tabs>
                {!carregando && tabValue === 0 && TabDetalhe()}

                {!carregando &&
                  tabValue === 1 &&
                  TabeFile("pdf", ".pdf", (pdf, index) => {
                    setViews(pdf);
                    setCurrentIndex(index);
                    setModalAbrirPDF(true);
                  })}
                {!carregando &&
                  tabValue === 2 &&
                  TabeFile("imagem", "image/*", (images, index) => {
                    setViews(images);
                    setCurrentIndex(index);
                    setModalAbrirImagens(true);
                  })}
                {!carregando &&
                  tabValue === 3 &&
                  TabeFile("video", "video/*", (videos, index) => {
                    setViews(videos);
                    setCurrentIndex(index);
                    setModalAbrirVideo(true);
                  })}

                {(is_admin || is_colaborador) && !carregando && tabValue === 5 && (
                  <Grid container spacing={2} paddingTop={5}>
                    <Grid item xs={12} mb={2} m={2}>
                      <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient">
                        {(is_admin || is_colaborador) && (
                          <Tooltip title={"Adicionar um usuário no projeto"} placeholder="bottom">
                            <MDButton
                              className="btn-add-usuario-projeto"
                              variant="gradient"
                              type="submit"
                              color="success"
                              key={"btn-adicionar-usuario-projeto"}
                              endIcon={<Icon>person_add</Icon>}
                              onClick={() => setModalAddUsuarioProjeto(true)}
                            >
                              ADICIONAR
                            </MDButton>
                          </Tooltip>
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                      <TabelaUsuariosProjeto
                        key={projeto.membros}
                        projeto_id={projeto_id}
                        data={projeto?.membros}
                        Atualizado={(usuarios) => setProjeto({ ...projeto, membros: usuarios })}
                      />
                    </Grid>
                  </Grid>
                )}
                <ModalGateway interactionIsIdle={true}>
                  {modalAbrirImagens ? (
                    <Modal onClose={() => setModalAbrirImagens(false)}>
                      <Carousel
                        views={views}
                        currentIndex={currentIndex}
                        showNavigationOnTouchDevice={true}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
                <>
                  {modalAbrirPDF && (
                    <AbrirPDF
                      url={views[currentIndex]?.source}
                      Close={() => setModalAbrirPDF(false)}
                    />
                  )}

                  {modalAbrirVideo && (
                    <ModalVideo
                      url={views[currentIndex]?.source}
                      Close={() => setModalAbrirVideo(false)}
                    />
                  )}

                  {modalAddUsuarioProjeto && (
                    <ModalRelacionarUsuarioNoProjeto
                      projeto_id={projeto_id}
                      Usuario={(user) => {
                        setProjeto({ ...projeto, membros: [...projeto.membros, user] });
                      }}
                      Close={() => setModalAddUsuarioProjeto(false)}
                    />
                  )}
                  {/* <PDFViewer
                    document={{
                      url: views[currentIndex]?.source,
                    }}
                  /> */}
                </>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Projeto;
