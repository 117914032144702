import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// eslint-disable-next-line react/prop-types
export default function AbrirPDF({ url, Close }) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    Close(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "100%",
          height: "100%",
          margin: 0,
          maxWidth: "none",
        },
      }}
    >
      <DialogContent
        style={{
          width: "100%",
          height: "100%",
          padding: 0,
        }}
      >
        <iframe title="PDF Viewer" width="100%" height="100%" src={url}></iframe>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}
