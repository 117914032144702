import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Footer() {
  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        px={1.5}
        fontSize={12}
      >
        &copy; {new Date().getFullYear()} Desenvolvido por &nbsp;&nbsp;
        <Link href={"https://github.com/raphaelvserafim"} target="_blank">
          Raphael Serafim
        </Link>
      </MDBox>
    </MDBox>
  );
}

export default Footer;
