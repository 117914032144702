import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

// Data
import TableData from "layouts/tables/data/colaboradorTableData";
import { useAuth } from "context/AuthContext";
import ColaboradoresTableCarregando from "layouts/tables/data/colaboradorTableCarregando";
import Usuario from "layouts/cadastrar/usuario";
import { useState } from "react";
import PagarLiberar from "components/PagarLiberar";

import CustomAPI from "../../CustomAPI";

function Colabores() {
  const [openCadastrar, setOpenCadastrar] = useState(false);
  const { usuario, Logout, sessao } = useAuth();
  const [carregando, setCarregando] = useState(true);
  const [podeCadastrar, setPodeCadastrar] = useState(false);
  const [temPermissao, setTemPermissao] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [alertarPagar, setAlertaPagar] = useState(false);

  useEffect(() => {
    if (usuario?.empresa?.is_admin === "sim") {
      setTemPermissao(true);
      if (
        usuario.empresa.empresa_situacao === "aguardando" ||
        usuario.empresa.empresa_situacao === "desativada"
      ) {
        setAlertaPagar(true);
        return;
      }
    }
    const fetchUsuarios = async () => {
      const hasAdminPermission = usuario?.empresa?.is_admin === "sim";
      const hasColaboradorPermission = usuario?.empresa?.is_colaborador === "sim";
      if (hasAdminPermission || hasColaboradorPermission) {
        setPodeCadastrar(true);
        setTemPermissao(true);
        if (hasAdminPermission) {
          try {
            const api = new CustomAPI(sessao);
            const resultado = await api.colaboradores(sessao);
            if (resultado.status) {
              console.log("resultado", resultado);
              setUsuarios(resultado.consulta);
              setCarregando(false);
            }
          } catch (error) {
            console.error("Erro ao enviar dados:", error);
          }
        }
      }
    };

    fetchUsuarios();
  }, [usuario]);

  return (
    <DashboardLayout>
      {openCadastrar && (
        <Usuario
          key={"cadastrando-colaborador"}
          usuario={2}
          tipo={1}
          Close={() => setOpenCadastrar(false)}
        />
      )}
      <DashboardNavbar />
      <MDBox py={5}>
        <Grid container spacing={3} mb={2}>
          {alertarPagar && <PagarLiberar />}
          {temPermissao && (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    <Icon fontSize="small">engineering</Icon> Colaboradores
                  </MDTypography>
                  <MDButton
                    style={{ position: "absolute", float: "right", right: "27px", top: "-7px" }}
                    variant="gradient"
                    type="submit"
                    color="dark"
                    endIcon={<Icon>add</Icon>}
                    onClick={() => setOpenCadastrar(true)}
                    disabled={alertarPagar}
                  >
                    Cadastrar
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  {carregando ? (
                    <ColaboradoresTableCarregando />
                  ) : (
                    <TableData usuarios={usuarios} key={usuarios} />
                  )}
                </MDBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Colabores;
