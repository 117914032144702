/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Skeleton from "@mui/material/Skeleton";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { useAuth } from "context/AuthContext";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import backgroundImage from "assets/images/fundo-c.jpeg";
import { URL_API } from "config";

// eslint-disable-next-line react/prop-types
function Header({ children, usuario, TabValue }) {
  const [img, setImag] = useState(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    if (usuario?.user_image) {
      setImag(`${URL_API}/${usuario.user_image}`);
    }
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    TabValue(newValue);
  };

  function uploadImage(event) {
    const fileInput = event.target;
    if (fileInput?.files[0]) {
      const url_previa = URL.createObjectURL(fileInput?.files[0]);
      setImag(url_previa);
    }
  }

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.2),
              rgba(gradients.info.state, 0.3)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {usuario?.user_image ? (
              <>
                <MDAvatar
                  className="img-peril"
                  src={img}
                  alt={usuario?.user_nome}
                  size="xl"
                  shadow="sm"
                />
                <input
                  onChange={uploadImage}
                  className="upload-image-perfil"
                  type="file"
                  accept="image/*"
                />
              </>
            ) : (
              <Skeleton width={80} height={80} variant="circular" animation="wave" />
            )}
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium" mb={1}>
                {usuario?.user_nome ? (
                  usuario?.user_nome
                ) : (
                  <Skeleton width={200} variant="rectangular" animation="wave" />
                )}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {usuario?.user_email ? (
                  usuario?.user_email
                ) : (
                  <Skeleton width={200} variant="rectangular" animation="wave" />
                )}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Perfil"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      badge
                    </Icon>
                  }
                />
                <Tab
                  label="Projeto"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      architecture
                    </Icon>
                  }
                />
                <Tab
                  label="Configurações"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
