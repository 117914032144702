import * as PDFJS from "pdfjs-dist/webpack";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
export function gerarNumerosAleatorios() {
  let senha = "";
  for (let i = 0; i < 6; i++) {
    const numeroAleatorio = Math.floor(Math.random() * 11);
    senha += numeroAleatorio.toString();
  }
  return senha;
}

export function gerandoToken(tamanho) {
  const tokenLength = tamanho || 8;
  const tokenBytes = new Uint8Array(tokenLength);
  window.crypto.getRandomValues(tokenBytes);
  const token = Array.from(tokenBytes)
    .map((byte) => byte.toString(16).padStart(2, ""))
    .join("");

  return token;
}

export async function consultarCEP(cep) {
  try {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
    if (!response.ok) {
      throw new Error("Erro ao consultar CEP");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Ocorreu um erro:", error);
    return null;
  }
}

export async function PdfThumbnail(urlPdf) {
  const pdf = await PDFJS.getDocument(urlPdf).promise;
  const page = await pdf.getPage(1);
  const scale = 1.5;
  const viewport = page.getViewport({ scale: scale });
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  await page.render({ canvasContext: context, viewport: viewport }).promise;
  const url = canvas.toDataURL();
  return url;
}

export async function ImageThumbnail(imageFile) {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = function (e) {
      const image = new Image();
      image.onload = function () {
        const thumbnailWidth = 300;
        const thumbnailHeight = (image.height / image.width) * thumbnailWidth;
        const canvas = document.createElement("canvas");
        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);

        const thumbnailDataUrl = canvas.toDataURL("image/jpeg");
        resolve(thumbnailDataUrl);
      };
      image.src = e.target.result; // Esta é a linha que faltava
    };
    fileReader.readAsDataURL(imageFile);
  });
}

export async function VideoThumbnail(videoFile) {
  const a = await generateVideoThumbnails(videoFile, 1);
  return a[0];
}
