/* eslint-disable react/prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ReactPlayer from "react-player";

export default function ModalVideo({ url, Close }) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    Close(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <ReactPlayer url={url} controls={true} width={"100%"} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}
