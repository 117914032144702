/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as React from "react";
import { lazy, Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Switch from "@mui/material/Switch";
import Skeleton from "@mui/material/Skeleton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
// Authentication layout components

// Images
import bgImage from "assets/images/fundo-a.jpg";

import Alertas from "components/Alerta";
import { useAuth } from "context/AuthContext";
import CustomAPI from "../../../CustomAPI";

const lazyLoad = (importStatement) => {
  const LazyComponent = lazy(importStatement);
  return (props) => (
    <Suspense fallback={<Skeleton variant="rectangular" height={"100%"} animation="wave" />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

const BasicLayout = lazyLoad(() => import("layouts/authentication/components/BasicLayout"));
const MDButton = lazyLoad(() => import("components/MDButton"));

function Acessar() {
  const navigate = useNavigate();
  const { authenticated, Login } = useAuth();
  const [salvando, setSalvando] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [lembra, setLembra] = useState(false);
  const [alertas, setAlerta] = React.useState({ show: false, message: "", type: "success" });
  const [dados, setDados] = useState({ email: "", senha: "" });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    const acesso = JSON.parse(localStorage.getItem("loginSenha"));
    if (acesso) {
      setDados(acesso);
      setLembra(true);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSalvando(true);
    const form = new FormData(event.currentTarget);
    const data = Object.fromEntries(form.entries());
    const api = new CustomAPI({ sessao: "nope" });
    const logando = await api.logando(data);
    setSalvando(false);
    if (logando.status) {
      if (lembra) {
        localStorage.setItem("loginSenha", JSON.stringify(data));
      } else {
        localStorage.removeItem("loginSenha");
      }
      setAlerta({ show: true, message: "Logado com sucesso", type: "success" });
      Login(logando.sessao);
      navigate("/dashboard");
    } else {
      setAlerta({ show: true, message: logando.message, type: "error" });
    }
  };

  useEffect(() => {
    if (authenticated) {
      navigate("/dashboard");
    }
  }, [authenticated]);

  return (
    <BasicLayout image={bgImage}>
      {alertas.show && (
        <Alertas
          message={alertas.message}
          severity={alertas.type}
          Close={() => setAlerta({ ...alertas, show: false })}
        />
      )}
      <Card>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-2}
          p={1}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h5" fontWeight="medium" color="black" mt={1}>
            ENTRAR
          </MDTypography>
          <MDTypography style={{ fontSize: 12 }} variant="span" color="red" mt={1}>
            Insira suas credenciais para acessar o sistema.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" noValidate={false} onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                key={dados?.email}
                defaultValue={dados?.email}
                type="email"
                name="email"
                label="E-mail"
                required={true}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                <OutlinedInput
                  key={dados?.senha}
                  defaultValue={dados?.senha}
                  name="senha"
                  required={true}
                  label="Senha"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth
                />
              </FormControl>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={lembra} onClick={() => setLembra(!lembra)} />
              &nbsp;&nbsp;
              <MDTypography mt={0} fontSize={12} variant="span" color="text">
                Lembrar
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                type="submit"
                color="success"
                fullWidth
                endIcon={<Icon>send</Icon>}
                disabled={salvando}
              >
                {salvando ? "Acessando..." : "Acessar"}
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Não tem uma conta?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Registre-se
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Acessar;
