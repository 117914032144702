import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Clientes from "layouts/clientes";
import Projetos from "layouts/projetos";
import Colaboradores from "layouts/colaboradores";
import Projeto from "layouts/projeto";
import Financeiro from "layouts/financeiro";
// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    private: false,
    isPrivate: false,
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    private: false,
    isPrivate: false,
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/dashboard",
    private: true,
    isPrivate: true,
    isAdmin: false,
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Projetos",
    key: "projetos",
    icon: <Icon fontSize="small">architecture</Icon>,
    route: "/projetos",
    private: true,
    isPrivate: true,
    isAdmin: false,
    component: <Projetos key="pagina-de-peojtos" />,
  },
  {
    type: "collapse",
    name: "Clientes",
    key: "clientes",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/clientes",
    private: true,
    isPrivate: true,
    isAdmin: true,
    component: <Clientes />,
  },
  {
    type: "collapse",
    name: "Financeiro",
    key: "billing",
    icon: <Icon fontSize="small">attach_money</Icon>,
    route: "/financeiro",
    private: true,
    isPrivate: true,
    isAdmin: true,
    component: <Financeiro />,
  },
  {
    type: "collapse",
    name: "Colaborador",
    key: "colaboradores",
    icon: <Icon fontSize="small">engineering</Icon>,
    route: "/colaboradores",
    private: true,
    isPrivate: true,
    isAdmin: true,
    component: <Colaboradores />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    private: true,
    isPrivate: false,
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "projeto",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/projetos/:projeto_id",
    private: true,
    isPrivate: false,
    component: <Projeto />,
  },
];

export default routes;
