/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import CustomAPI from "CustomAPI";
const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(Cookies.get("sessao"));
  const [usuario, setUsuario] = useState({});
  const [sessao, setSessao] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (!authenticated) {
        return;
      }
      try {
        const user = JSON.parse(atob(authenticated));
        const s = {
          token: user?.sessao,
          usuario: user?.id,
          empresa: user?.empresa?.empresa,
        };
        const api = new CustomAPI(s);
        const verificando = await api.getSessao();
        if (verificando.status) {
          const user = JSON.parse(atob(verificando.sessao));
          setUsuario(user);
          setSessao(s);
        } else {
          Cookies.remove("sessao");
          setAuthenticated(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [authenticated]);

  const Login = (sessao) => {
    Cookies.set("sessao", sessao);
    const user = JSON.parse(atob(sessao));
    setUsuario(user);
    const s = {
      token: user?.sessao,
      usuario: user?.id,
      empresa: user?.empresa?.empresa,
    };
    setSessao(s);
    setAuthenticated(true);
  };

  const Logout = () => {
    Cookies.remove("sessao");
    setAuthenticated(false);
  };
  console.log("\x1b[31m%s\x1b[0m", "╔═══════════════════════════════════════════════════════╗");
  console.log("\x1b[31m%s\x1b[0m", "║Alguma dúvida ? https://github.com/raphaelvserafim     ║");
  console.log("\x1b[31m%s\x1b[0m", "╚═══════════════════════════════════════════════════════╝");
  return (
    <AuthContext.Provider value={{ authenticated, usuario, sessao, Login, Logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
