/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Skeleton from "@mui/material/Skeleton";
import DataTable from "examples/Tables/DataTable";

const Author = () => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <Skeleton variant="circular" width={30} height={30} />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium" mb={1}>
        <Skeleton variant="rectangular" width={100} animation="wave" />
      </MDTypography>
      <MDTypography variant="caption">
        <Skeleton variant="rectangular" width={130} animation="wave" />
      </MDTypography>
    </MDBox>
  </MDBox>
);

const Job = () => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      <Skeleton variant="rectangular" width={130} animation="wave" />
    </MDTypography>
    <MDTypography variant="caption">
      <Skeleton variant="rectangular" width={130} animation="wave" />
    </MDTypography>
  </MDBox>
);

function Data() {
  const rows = [];
  function modelo() {
    return {
      author: <Author />,
      status: (
        <MDBox ml={-1}>
          <Skeleton variant="rectangular" width={50} animation="wave" />
        </MDBox>
      ),
      action: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <Skeleton variant="rectangular" width={50} animation="wave" />
        </MDTypography>
      ),
    };
  }

  for (let index = 0; index < 5; index++) {
    rows.push(modelo());
  }

  return {
    columns: [
      { Header: "Nome", accessor: "author", align: "left" },
      { Header: "Status", accessor: "status", width: 50, align: "center" },
      { Header: "Ação", accessor: "action", width: 50, align: "center" },
    ],
    rows: rows,
  };
}

export default function clientesTableCarregando() {
  const { columns, rows } = Data();
  return (
    <DataTable
      table={{ columns, rows }}
      canSearch={false}
      pagination={false}
      isSorted={false}
      entriesPerPage={false}
      showTotalEntries={false}
      noEndBorder
    />
  );
}
