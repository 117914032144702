import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useAuth } from "context/AuthContext";

function Financeiro() {
  const [carregando, setCarregando] = useState(true);
  const [is_admin, setIsadmin] = useState(false);
  const { usuario, Logout, sessao } = useAuth();
  useEffect(() => {
    if (usuario?.empresa) {
      if (usuario?.empresa?.is_admin === "sim") {
        setIsadmin(true);
      }
    }
  }, [usuario]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card></Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Financeiro;
