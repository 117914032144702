/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { URL_API } from "config";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "context/AuthContext";
import CustomAPI from "CustomAPI";
import DataTable from "examples/Tables/DataTable";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

export default function TabelaUsuariosProjeto({ projeto_id, data, Atualizado }) {
  const { sessao } = useAuth();
  const [usuarios, setUsuarios] = useState(data);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([
    { Header: "Nome", accessor: "nome", align: "left" },
    { Header: "Permissão", accessor: "permissao", align: "center" },
    { Header: "Ação", width: 60, accessor: "acao", align: "center" },
  ]);
  const [removingUserId, setRemovingUserId] = useState(null);

  const Author = ({ image, name, email }) => (
    <MDBox key={email} display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  function modelo(usuario) {
    return {
      nome: (
        <div>
          {removingUserId === usuario.user_id ? (
            <Skeleton variant="circular" width={30} height={30} />
          ) : (
            <Author
              image={`${URL_API}/${usuario?.user_image}`}
              name={usuario?.user_nome}
              email={usuario?.user_email}
            />
          )}
        </div>
      ),
      permissao: (
        <div>
          {removingUserId === usuario.user_id ? (
            <Skeleton variant="rectangular" width={130} animation="wave" />
          ) : (
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={usuario?.permissao}
              >
                <MenuItem value={"cliente"}>Cliente</MenuItem>
                <MenuItem value={"editor"}>Editor</MenuItem>
                <MenuItem value={"supervisor"}>Supervisor</MenuItem>
              </Select>
            </FormControl>
          )}
        </div>
      ),
      acao: (
        <div style={{ display: "flex", alignItems: "center" }}>
          {removingUserId === usuario.user_id ? (
            <Skeleton variant="circular" width={20} height={20} />
          ) : (
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => handleRemover(usuario.user_id)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          )}
        </div>
      ),
    };
  }
  useEffect(() => {
    console.log("usuarios", usuarios);
    if (usuarios?.length > 0) {
      const a = [];
      usuarios?.map((usuario) => {
        a.push(modelo(usuario));
      });
      setRows(a);
    } else {
      setRows([]);
    }
  }, [usuarios, removingUserId]);

  async function handleRemover(id) {
    setRemovingUserId(id);
    const api = new CustomAPI(sessao);
    const removendo = await api.removendoUsuarioDoProjeto(id, projeto_id);
    if (removendo.status) {
      const users = usuarios.filter((user) => user.user_id !== id);
      setUsuarios(users);
      Atualizado(users);
    }
    setRemovingUserId(null);
  }

  return (
    <DataTable
      key={"usuario-do-projeto"}
      table={{ columns, rows }}
      canSearch={false}
      pagination={true}
      isSorted={true}
      entriesPerPage={false}
      showTotalEntries={false}
      noEndBorder
    />
  );
}
