/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Alertas from "../components/Alerta";

import CustomAPI from "../CustomAPI";
import { useAuth } from "../context/AuthContext";
import { URL_API } from "config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalRelacionarUsuarioNoProjeto({ projeto_id, Close, Usuario }) {
  const [open, setOpen] = React.useState(true);
  const [adicionando, setAdicionando] = React.useState(false);

  const [api, setApi] = React.useState(null);
  const [usuarios, setUsuarios] = React.useState([]);
  const { sessao } = useAuth();
  const [usuario, setUser] = React.useState(null);
  const [permissao, setPermissao] = React.useState(null);
  const [alertas, setAlerta] = React.useState({ show: false, message: "", type: "success" });

  React.useEffect(() => {
    if (sessao) {
      setApi(new CustomAPI(sessao));
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    Close(false);
  };

  async function adicionar() {
    if (!permissao) {
      setAlerta({ show: true, message: "selecionar permissão antes", type: "error" });
      return;
    }
    if (!usuario) {
      setAlerta({ show: true, message: "selecionar usuário antes", type: "error" });
      return;
    }
    setAdicionando(true);
    const adicionando = await api.adicionandoUsuarioProjeto({
      projeto_id: projeto_id,
      user_id: usuario?.user_id,
      permissao: permissao,
    });
    if (adicionando.status) {
      setAlerta({ show: true, message: "Adicionado", type: "success" });
      Usuario({ ...usuario, permissao: permissao });
      handleClose();
    } else {
      setAlerta({ show: true, message: adicionando.message, type: "error" });
    }
    setAdicionando(false);
  }

  async function pesquisando(nome) {
    if (nome && nome.length > 2) {
      const resultado = await api.buscandoUsuarioPorNome(nome);
      if (resultado.status && resultado.consulta.length > 0) {
        setUsuarios(resultado.consulta);
      }
    }
  }
  const handleAutocompleteChange = (event, usuario) => {
    if (usuario) {
      setUser(usuario);
    } else {
      setUser(null);
    }
  };
  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>{"Adicionar usuário no projeto"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} py={3}>
            <Grid item xs={12} mb={1}>
              <Autocomplete
                fullWidth
                options={usuarios}
                getOptionLabel={(option) => option.user_nome}
                onChange={handleAutocompleteChange}
                renderOption={(props, option) => (
                  <MDBox {...props}>
                    <MDAvatar
                      src={`${URL_API}/${option.user_image}`}
                      alt={option.user_nome}
                      size="sm"
                    />
                    <MDBox ml={2} lineHeight={1}>
                      <MDTypography display="block" variant="button" fontWeight="medium">
                        {option.user_nome}
                      </MDTypography>
                      <MDTypography variant="caption">{option.user_email}</MDTypography>
                    </MDBox>
                  </MDBox>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(event) => {
                      pesquisando(event?.target?.value);
                    }}
                    label="Buscar usuário"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <FormControl>
                <RadioGroup row onChange={(event) => setPermissao(event.target.value)}>
                  <FormControlLabel value="cliente" control={<Radio />} label="Cliente" />
                  <FormControlLabel value="editor" control={<Radio />} label="Editor" />
                  <FormControlLabel value="supervisor" control={<Radio />} label="Supervisor" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} mb={2}>
              <MDButton
                variant="gradient"
                type="submit"
                color="success"
                key={"btn-adicionar-usuario-projeto"}
                endIcon={<Icon>send</Icon>}
                onClick={() => adicionar()}
                disabled={adicionando}
              >
                {adicionando ? "ADICIONANDO..." : "ADICIONAR"}
              </MDButton>
            </Grid>
          </Grid>
          {alertas.show && (
            <Alertas
              message={alertas.message}
              severity={alertas.type}
              Close={() => setAlerta({ ...alertas, show: false })}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
