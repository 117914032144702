/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import MDInput from "components/MDInput";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useEffect, useState } from "react";
import EditarProfile from "./editar";
import CustomAPI from "../../CustomAPI";
import { useAuth } from "context/AuthContext";

function Overview() {
  const { sessao } = useAuth();
  const [tab, setTab] = useState(0);
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (sessao?.usuario) {
      const api = new CustomAPI(sessao);
      api.meuPerfil().then((resultado) => {
        console.log(resultado);
        if (resultado.status) {
          setUser(resultado.consulta[0]);
        }
      });
    }
  }, [sessao]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header key={user} usuario={user} TabValue={(e) => setTab(e)}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            {tab === 2 && (
              <Grid item xs={12} md={6} xl={4}>
                <PlatformSettings />
              </Grid>
            )}
            {tab === 0 && (
              <Grid item xs={12} md={7} sx={{ display: "flex" }}>
                <EditarProfile key={user} user={user} />
              </Grid>
            )}
          </Grid>
        </MDBox>
        {tab === 1 && (
          <>
            <MDBox pt={2} px={2} lineHeight={1.25}>
              <MDTypography variant="h6" fontWeight="medium">
                Projetos
              </MDTypography>
              <MDBox mb={1}>
                <MDTypography variant="button" color="text">
                  Architects design houses
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor1}
                    label="project #2"
                    title="modern"
                    description="As Uber works through a huge amount of internal management turmoil."
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "info",
                      label: "view project",
                    }}
                    authors={[
                      { image: team1, name: "Elena Morison" },
                      { image: team2, name: "Ryan Milly" },
                      { image: team3, name: "Nick Daniel" },
                      { image: team4, name: "Peterson" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor2}
                    label="project #1"
                    title="scandinavian"
                    description="Music is something that everyone has their own specific opinion about."
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "info",
                      label: "view project",
                    }}
                    authors={[
                      { image: team3, name: "Nick Daniel" },
                      { image: team4, name: "Peterson" },
                      { image: team1, name: "Elena Morison" },
                      { image: team2, name: "Ryan Milly" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor3}
                    label="project #3"
                    title="minimalist"
                    description="Different people have different taste, and various types of music."
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "info",
                      label: "view project",
                    }}
                    authors={[
                      { image: team4, name: "Peterson" },
                      { image: team3, name: "Nick Daniel" },
                      { image: team2, name: "Ryan Milly" },
                      { image: team1, name: "Elena Morison" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor4}
                    label="project #4"
                    title="gothic"
                    description="Why would anyone pick blue over pink? Pink is obviously a better color."
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "info",
                      label: "view project",
                    }}
                    authors={[
                      { image: team4, name: "Peterson" },
                      { image: team3, name: "Nick Daniel" },
                      { image: team2, name: "Ryan Milly" },
                      { image: team1, name: "Elena Morison" },
                    ]}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </>
        )}
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
